import React from "react";
import css from './offeringSection.module.scss';
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, Link, useStaticQuery} from "gatsby";
import {useTranslation} from "react-i18next";
import MainButton, {mainButtonArrow, mainButtonFill} from "../../shared/MainButton/MainButton";
import {getLink, getRoutes} from "../../shared/routes";
import DremaLogo from "../../../images/sections/offeringSection/DremaLogo.svg"

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_offering_section"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const OfferingSection = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const renderSection = (index, imgScale = 1, imageAlt, title, text, image, routes, type = 'TimberID') => {
        return (
            <div className={css.section}>
                <div className={css.sectionContentWrapper}>
                    <div className={css.sectionTitle}>
                        <div className={css.textBold}>{type}</div>
                        <div>{title}</div>
                    </div>
                    <div className={css.sectionImageWrapper} style={{width: `${100 * imgScale}%`}}>
                        {renderImageByFileName(imagesFluid, image, imageAlt)}
                    </div>
                    <div className={css.sectionText}>
                        {text}
                    </div>
                    <Link to={getLink([routes])}>
                        <MainButton
                            label={'Contact us'}
                            arrow={mainButtonArrow.right}
                            fill={mainButtonFill.gradient}
                        />
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperInner}>
                <div className={css.text}>
                    <span className={css.textBold}>TimberID</span> is a cohesive platform consisting of interconnected
                    functionalities. Offered as a complete solution.
                    <br/><br/>
                    As a client, you can decide to utilize the entire platform or select specific features that meet
                    your requirements.
                </div>
                {/*<a href={"https://drive.google.com/drive/u/0/folders/1cfkICrAyZsGCLJ9DneCHnFfVX5X99hXO"}>*/}
                {/*    <div className={css.bannerWrapper}>*/}
                {/*        <div className={css.banner}>*/}
                {/*            <div className={css.imageWrapper}>*/}
                {/*                <img src={DremaLogo} alt={'Drema Logo'}/>*/}
                {/*            </div>*/}
                {/*            <div className={css.text2}>*/}
                {/*                <span className={css.textBold}>Drema 2024 Guest ? </span><br/>*/}
                {/*                Click on the banner to download files*/}
                {/*            </div>*/}
                {/*            <MainButton label={"Download"} arrow={mainButtonArrow.rightDown}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</a>*/}
                <div className={css.sectionsWrapper}>
                    {renderSection(1, 1, "Offering Dcode", 'Apply and read', "Applying a Dcode on a timber log with a selected marker, using the Dcode Reader app for encrypted data read and export.", "Offering_Dcode.png", getRoutes().OfferingDcodeApplyAndRead, 'Dcode')}
                    {renderSection(1, 1, "Offering AP", 'Authentication Platform', "Applying a Dcode on a timber log with a selected marker, using the TimberID app for data sourcing, cloud data processing and storing, providing the authentication of provenance proof.", "Offering_AP.png", getRoutes().OfferingTimberIDAuthenticationPlatform)}
                    {renderSection(1, 0.6, "Offering EUDR", 'EUDR Platform', "Seamless automated data creation and storage based on defined processes meeting the regulatory objectives, creating a DDS, submitting to EU Trace portal.", "Offering_EUDR.png", getRoutes().OfferingTimberIDEUDRPlatform)}
                </div>
            </div>
        </div>
    );
};

export default OfferingSection;
